import EnglishFlag from "static/flag/en.png";
import ChineseFlag from "static/flag/cn.png";

export const Languages = {
  en: {
    icon: EnglishFlag,
    title: "EN",
  },
  cn: {
    icon: ChineseFlag,
    title: "中文",
  },
};
