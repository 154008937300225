import { Tranche, PoolType, Tokens } from "../constant";

export const strategy7 = "0x854E3dd6231CDa9589Be8964B03f7B1baF4eb6EA";

export const epoch7Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0x9f755c3AC805FB38701484603d7CE0Cd996b7A75",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 16.40625,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0xA755c25228557418aCdE75695fAe3eb9Fc8C6268",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 46.875,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x6E516CC0Def9DFe934627BdE94edf284E788F0f6",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 35.15625,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0xbca90B2B19DA72d7d1c7558F905A72F2A3773E07",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 5.15625,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x1c30Ff26A0d18A2EceA4Ab266D256b1a5eD7f9E3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 16.40625,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x6BafcDCea496F02adefc4E7AFD621F6C4Ad2D2F4",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 2.8125,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0x9BdD6a35455DE49c0E9FB97557Cec53c13aB9a0F",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 5.15625,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0xE52b537ebB73e258EBe51580dFa0071064CfE375",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 46.875,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0x87ea4e947757E685E9eFDcDA40b92F52d458d402",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 5.15625,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0x3e522a15207A4Cc4246ADcbf4390bAFd5a798EC6",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 16.40625,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0xD8bF3B82DE36ebdf420B93e5798212764aAcfd2C",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 5.15625,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0xeB3fc717c2a2F86Ad86fA894f8b73121bee345C1",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 16.40625,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x1A44AF60201B658a2cb284aF68AA9D3434dD736a",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 16.40625,
  },
];
