import { Tranche, PoolType, Tokens, StakingType } from "../constant";

export const strategy5 = "0x304375B943d33AC58327752ec7Ac277aB41Fb0AF";
export const epoch5Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0x0033D4276440c4d9D44e7551D3cb5F631872F754",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 100,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0x730c034D7Ed43CEA0D47a681E2bc8343D1fB6450",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 205,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x5b8acA478d24f0AFa830355091015546a6503CD6",
    type: PoolType.StakingPool,
    detail: StakingType.SFIStaking,
    pair: [Tokens.SfiStaking],
    reward: 105,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0x0dAFdaD5c7d2e9B4f7A8F27c10DCA704D37eAb93",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 50,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0xdB783680D4De11e68aC48519E0d8A3A9E37EF909",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0xC23dE63644ee95c3Fab7DfE3dFa22aa57068e131",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 25,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0xe59603258Dab9e6A0Fed6845B29cc1EE5fa28Bec",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 205,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0xED2EBC251B8FEBab8345bcc48Ebaa6Ce1220B6ec",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 25,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0xFa1117EB3dB5055A22CA19CC6654031E485dAD90",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 100,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0xbe6984A6fCd7B46B529A3dA77DDdAAD1Ba2377ba",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 12.5,
  },
];
