import { withTheme } from "styled-components";
import styled from "styled-components";

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
`;
export const AddLiquidityIcon = withTheme(({ theme, active }) => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" width={22} viewBox="0 0 129 142.51">
      <g id="Layer_2" fill={active ? theme.icon.active : theme.icon.color} data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M129,34.6v3.26a2.7,2.7,0,0,1-2.69,2.69H105.23v20a2.69,2.69,0,0,1-2.69,2.68H99.28a2.7,2.7,0,0,1-2.69-2.68v-20h-20a2.7,2.7,0,0,1-2.68-2.69V34.6a2.69,2.69,0,0,1,2.68-2.69h20V10.83a2.7,2.7,0,0,1,2.69-2.69h3.26a2.69,2.69,0,0,1,2.69,2.69V31.91h21.08A2.69,2.69,0,0,1,129,34.6Z" />
          <path d="M70.64,24.78C65.08,17.1,59.36,9.17,54.49,1.51a3.27,3.27,0,0,0-5.51,0C44,9.37,38.1,17.5,32.4,25.37,16.47,47.37,0,70.13,0,90.78a51.74,51.74,0,0,0,103.47,0,47.6,47.6,0,0,0-2-13.14C39.05,56.42,60,33.13,70.64,24.78Z" />
        </g>
      </g>
    </svg>
  </Box>
));

export const CommunityIcon = withTheme(({ theme, active }) => (
  <Box>
    <svg width={22} viewBox="0 0 143 135" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M71.5 0C32.0435 0 0 26.5028 0 59.1978C0 77.0314 9.56522 93.1312 24.8696 104.03C28.4565 106.506 28.6957 111.956 25.587 114.928L18.413 121.863C15.0652 125.331 15.7826 131.028 20.087 133.257C21.7609 134.248 23.913 134.248 25.587 133.505L61.4565 118.396C62.413 117.9 63.6087 117.9 64.5652 117.9C66.7174 118.148 69.1087 118.148 71.2609 118.148C110.717 118.148 142.761 91.6451 142.761 58.9501C143 26.5028 110.957 0 71.5 0ZM34.6739 73.0684C28.2174 73.0684 22.7174 67.6192 22.7174 60.6839C22.7174 53.9963 27.9783 48.2994 34.6739 48.2994C41.3696 48.2994 46.6304 53.7486 46.6304 60.6839C46.6304 67.3715 41.3696 73.0684 34.6739 73.0684ZM71.5 73.0684C65.0435 73.0684 59.5435 67.6192 59.5435 60.6839C59.5435 53.9963 64.8044 48.2994 71.5 48.2994C78.1957 48.2994 83.4565 53.7486 83.4565 60.6839C83.4565 67.3715 77.9565 73.0684 71.5 73.0684ZM108.087 73.0684C101.63 73.0684 96.1304 67.6192 96.1304 60.6839C96.1304 53.9963 101.391 48.2994 108.087 48.2994C114.543 48.2994 120.043 53.7486 120.043 60.6839C120.043 67.3715 114.783 73.0684 108.087 73.0684Z"
        fill={active ? theme.icon.active : theme.icon.color}
      />
    </svg>
  </Box>
));

export const DashboardIcon = withTheme(({ theme, active }) => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" width={22} viewBox="0 0 144 130">
      <g id="Layer_2" fill={active ? theme.icon.active : theme.icon.color} data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect y="67" width="36" height="63" />
          <rect x="54" y="85" width="36" height="45" />
          <rect x="108" y="40" width="36" height="90" />
          <circle cx="18" cy="38" r="11" />
          <circle cx="72" cy="55" r="11" />
          <circle cx="126" cy="11" r="11" />
          <rect x="42.5" y="18.19" width="5" height="56.61" transform="translate(-12.87 75.46) rotate(-72.53)" />
          <rect x="63.67" y="30" width="69.66" height="5" transform="translate(1.61 69.52) rotate(-39.17)" />
        </g>
      </g>
    </svg>
  </Box>
));

export const DocsIcon = withTheme(({ theme, active }) => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" width={22} viewBox="0 0 102.86 144">
      <g id="Layer_2" fill={active ? theme.icon.active : theme.icon.color} data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M102.86,12.57V35.38L67.47,0H90.29A12.57,12.57,0,0,1,102.86,12.57Z" />
          <path d="M56.14,0H12.57A12.57,12.57,0,0,0,0,12.57V131.43A12.57,12.57,0,0,0,12.57,144H90.29a12.57,12.57,0,0,0,12.57-12.57V46.72ZM43.45,51l4.23-8.21,6.58,0,4.25,8.17c2.31,4.44,4.16,8.12,4.16,8.25-.21.2-6.42,3.55-6.56,3.55a24,24,0,0,1-1.69-3.16c-.84-1.72-2-4.43-2.52-6l-1-2.86-.5,1.94c-.62,2.32-4,9.74-4.5,9.74a34.11,34.11,0,0,1-3.51-1.61l-3.19-1.59Zm-.39,53.18H28.47V96.77H43.06Zm0-12H28.47V84.79H43.06Zm0-12H28.47V72.81H43.06Zm31,23.95H59.45V96.77h14.6Zm0-12H59.45V84.79h14.6Zm0-12H59.45V72.81h14.6Zm-.79-20A18.73,18.73,0,0,1,65,68.53c-2.66,1.28-7.23,2.75-8.55,2.75-1.55,0-1.71.19-1.45,1.78.12.79.24,9.49.25,19.33l0,17.88H47.2l.09-19.34c.09-19.34.09-19.34-.95-19.51a23.35,23.35,0,0,1-10.25-3.74A17.59,17.59,0,0,1,28.3,56.34c-.71-3.3-.46-6.17.9-10.35s1.44-5.75.24-8.13l-.93-1.81,3.71-1.31c4.2-1.47,4.13-1.48,5.22,1s1,6.17-.3,10.14-1.54,5.86-1.21,8.34c.66,4.81,3.73,7.92,9.14,9.27,2.93.73,10.29.73,12.88,0a14.3,14.3,0,0,0,6.45-3.66c2.89-3.29,3.41-7.54,1.63-13.27-1.62-5.2-1.54-9.67.23-12.08.53-.72.53-.72,3.49.09,5.37,1.49,5,1.19,3.9,3-1.27,2.1-1.24,3.8.19,8.79C75.47,52,75.33,55.54,73.26,60.19Z" />
        </g>
      </g>
    </svg>
  </Box>
));

export const PortfolioIcon = withTheme(({ theme, active }) => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" width={22} viewBox="0 0 144 143.73">
      <g id="Layer_2" fill={active ? theme.icon.active : theme.icon.color} data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M78,0V25.13a47,47,0,1,1-14.69,92.78l-19.9,19.91A72,72,0,1,0,78,0Z" />
          <path d="M25,71.73a46.77,46.77,0,0,1,10-29L17.27,25A72,72,0,0,0,32.4,131.86l18.27-18.27A47,47,0,0,1,25,71.73Z" />
          <path d="M66,25.13V0A71.67,71.67,0,0,0,25.79,16.53l17.79,17.8A46.79,46.79,0,0,1,66,25.13Z" />
        </g>
      </g>
    </svg>
  </Box>
));

export const RedeemIcon = withTheme(({ theme, active }) => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" width={22} viewBox="0 0 143.27 143.27">
      <g id="Layer_2" fill={active ? theme.icon.active : theme.icon.color} data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M72.13,20.89a50.25,50.25,0,1,0,50.25,50.25A50.24,50.24,0,0,0,72.13,20.89ZM87.47,93.28a21.12,21.12,0,0,1-11.65,4.88V102a3,3,0,0,1-3,3h-3a3,3,0,0,1-3-3V98a23.09,23.09,0,0,1-7.52-2.75A19.55,19.55,0,0,1,53.5,90a10.94,10.94,0,0,1-2.3-6.13,2.61,2.61,0,0,1,2.6-2.62h3a3.48,3.48,0,0,1,1.85.48,3.84,3.84,0,0,1,1.31,1.7,11.06,11.06,0,0,0,3.93,4.4c1.88,1.34,4.51,2,7.83,2a15.68,15.68,0,0,0,8.46-2.15c2.16-1.34,3.21-3,3.21-5.15a4.11,4.11,0,0,0-1.58-3.28,15.22,15.22,0,0,0-5-2.59c-2.2-.74-5.47-1.67-9.76-2.78-4.88-1.25-8.47-3.13-10.68-5.57A13.24,13.24,0,0,1,53,59.13a14.31,14.31,0,0,1,1.68-6.76,14.49,14.49,0,0,1,5-5.35,18.67,18.67,0,0,1,7.19-2.65V40.42a3,3,0,0,1,3-3h3a2.86,2.86,0,0,1,2.09.88,2.83,2.83,0,0,1,.87,2.07v4a20.3,20.3,0,0,1,7.38,2.85,18.3,18.3,0,0,1,5.49,5.23,11.82,11.82,0,0,1,2.13,5.58A2.27,2.27,0,0,1,90.06,60a2.65,2.65,0,0,1-1.84.74h-3a3.05,3.05,0,0,1-1.91-.53,4.55,4.55,0,0,1-1.23-1.59,12.94,12.94,0,0,0-3.76-4.12,11.38,11.38,0,0,0-7-2,12.41,12.41,0,0,0-6.85,1.8,5.32,5.32,0,0,0-2.56,4.77,4.58,4.58,0,0,0,1.21,3.35,10.93,10.93,0,0,0,4.14,2.45c2,.74,5,1.64,9,2.71C82.06,69.14,86,71,88.49,73.21a12.11,12.11,0,0,1,3.78,9.36A13.83,13.83,0,0,1,87.47,93.28Z" />
          <path d="M71.64,10.87A60.77,60.77,0,1,1,10.87,71.64,60.84,60.84,0,0,1,71.64,10.87M71.64,0a71.64,71.64,0,1,0,71.63,71.64A71.63,71.63,0,0,0,71.64,0Z" />
        </g>
      </g>
    </svg>
  </Box>
));
