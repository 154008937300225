import { Tranche, PoolType, Tokens } from "../constant";

export const strategy6 = "0xC7a70b08c826da7509E48c3E555574292915bd21";

export const epoch6Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0xBB3c0d82a2DA2dC2D4d3fe1b063913f0Dc1C2C8c",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 32.8125,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0xdffc04e6B234Cb3Fc3d54175B0745Ad8E6D26e06",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 93.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x85dDecf31cF250cb73673E61A97DC616db37cdFe",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 70.3125,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0x3DB8D7ac19182A6AB88F372465c429EE925D40a9",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 10.3125,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x7D21bA0975A330C4c49677F15d29a090c7139EeF",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 32.8125,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x4C5487759A42a4817fF7e2672975d923a27fAbdD",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 5.625,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0x3470860C6b6078e13b12959150a34442FFdc7A39",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 10.3125,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0x26ec54Bd16Eb8e795d1bF2120c970a25EA7a5a07",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 93.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0x897f548973b4b7c73567C3018a2B7E55285C9ADf",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 10.3125,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0x7F50321f11576714Dc0BdD5ef9e77De52bF73C05",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 32.8125,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0xeB770530Ee60B58fF5f3C38a085327997c2f8826",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 10.3125,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x458bD79418464Bc3750deA8932406539e5f37Dc3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 32.8125,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0xBd02AF78f5161dc335E3c50e880D0ED4B0828004",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 32.8125,
  },
];
