import { Tranche, PoolType, Tokens, StakingType } from "../constant";

export const strategy4 = "0xf4aa3b60ead8df9768816f20710a99bbf372393c";
export const epoch4Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0x74E4CeaEDAA4856e5A417047C9e3077B7E088Eb9",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 200,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0x88597998Df22Fc449Cb0ba4DA01c611302df35CC",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 410,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0xe1E76B570a032293bb6cF8A21B1C0e6c08B8c185",
    type: PoolType.StakingPool,
    detail: StakingType.SFIStaking,
    pair: [Tokens.SfiStaking],
    reward: 210,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0xA7D4b663Fa11444c636ABEf8e23Daf3ac800009a",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 100,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x4D1D95F9E96C46f428bC8Ea797Ca39A3caF60268",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 10,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0x31017f8B1Edd041Caca5dBB4D9d2996bB6b880cE",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 50,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0x212fa9E030135d09CD9e2a02aB02B13Ec94ee5aB",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 410,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0x190972727Fc7b01Af3bef04A6769CBA18815cF01",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 50,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0xbafA231AAac12CE8ba0b23b86669f54a05fC23b5",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 200,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x0aAEbad2328580a948d82ED8C2D621ff537B8eEC",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 25,
  },
];
