import { Tranche, PoolType, Tokens } from "../constant";

export const strategy19 = "0x444a9e60b28064B9ce94CccE074842082a36a4C4";

export const epoch19Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0xD2042EAb071792702d6d5c74A2a45cA68C42A887",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0xD5c3dde4056E3fCec71DDeB672975fe78d06E33D",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0xaefA292146C26d4497d09D0EDeb2fE6F7C0a4364",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0xD0FDEA370c34737734259A554F926FDB8eF55FFC",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0xD09e02B5D8CBfcEbb790910a51f14EE066Dc969c",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 15.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x1B7b0bb66dDCA9D97B1EfF8D4DfA3E6D779644c9",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0xf0A402E8E62ea163bc971ddA7EeB798CdEBd56BB",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0x956BDaBB7E25EE00c4997b1F14470E2d91d31F83",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0xd8DA5f9CDE80E49D3DfB14f32D6A60DCB70C942B",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0x36916A0Bd0C7689A50B21B131fE06A4d839627d7",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x964A29439C414D0De28F7e30Ef20B2a650371E15",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x3ec58960D75AA859e207d622013329615577f7Fd",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x31186cC956220b6f7F3Cd4687f5d30c5e3ad4b04",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
