import { Tranche, PoolType, Tokens } from "../constant";

export const strategy9 = "0x769e038F3c449C096DE53245993B9b622741264a";

export const epoch9Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0x75574C46691f50FD5534a21bAB8401B895da9B89",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0xC2c4f86deC7731a5Ca7E348012F55f392E014dF9",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0xD6168368634A9657173d19104172E2C4593c26f2",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0x9cB9d0636362C8BF57B5D5B5599223F51ca32131",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x081ffAb44D30CbF9d8852306C227ba998f018428",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x0007a7e27dB427E7BA4edA4BAA41FC0E9f90719F",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0x6AE05B5a9E4fe3de31B5348CC8CeD6FF72C31e0b",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0xd6DfC4e6636C9D2F16553d7Efcc4Ed4939342393",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0x4c5F679ee63dc36e1Bf0b402F3aF45Fb19623A79",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0xFc2f466b2b72f9D5269D7C1945f840549A5FeE58",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0xEd2C36A71A239379Ce018069500C1AD0c704c5fB",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x9e2E9Cd9F9A23B86Fc4F097f6A45913fE44EF880",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x764C4DC595844C619457a74c4Ac84827ffA8e401",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
