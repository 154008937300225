import { Skeleton } from "antd";

const SkeletonBox = ({ height, width, radius }) => (
  <Skeleton.Input
    active
    size="small"
    style={{ width: width ?? 100, height: height ?? 30, borderRadius: radius ?? 8 }}
  />
);

export default SkeletonBox;
