import { Tranche, PoolType, Tokens } from "../constant";

export const strategy16 = "0x80687587483BcA19927f7CCefb408c53c32dd162";

export const epoch16Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0xa5fa42CE5FE609d1E20f0D22f9B269bF9B284D39",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0xBfC75F5FB98b3Dad8c4Bb6d8aDc17EA2E626c912",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x999a8bf3074E43AD5c5873eec16f24b808F53292",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0x77fB32743eDF3FefEA422cF71dA22c2594F75663",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x527633BB641b601f8c06515C06E1C64e9B0648f3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 15.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x04CD56ba45469c887E28d9ba297f2b39CB642B91",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0xb396a25c38BE76E667c36A94ccED5A1b9A5214c3",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0xbF9D3C2f211555Ff3E6c7221fE5E78f0642375DD",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0xcA3a9b5e3741d2A5fF7B5Bb77352a97D04c8c74F",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0x0512Dc87aFD3bF23152E4e67BDFDbef943e79daf",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x70A3cf7aB9a4BBeA263E927DAd80F780028Fd763",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0xb13A8Aa37246A16698B6A82E9D556eE48fe1dCEd",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0xbE3F0090137fc2C0183394f5bb1ABf8886218E1C",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
