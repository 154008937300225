import { Tranche, PoolType, Tokens } from "../constant";

export const strategy10 = "0x5834aFA9612f029351646EF0Ea18F35b7dA77A28";

export const epoch10Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0xf419345D943e49BBdb23dEE7c07A00BAC51D7Dde",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0x7296f01e8e6e02b87a1A101eAfA57FFB26a76c33",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x223cBF51f154Ffe81f29B825eF47b0cAD8f691c2",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0xfe491E1b42413Cab520849810F60C4Fe26BfE87F",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x9Ed956bEAAb972d141336B9085BB390C6BEf01F3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x08EeaB4EBf3645D03E2A2B3842A505586B467374",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0xD859fBbD14085d2E096264e324C3006E7841e377",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0xe61C9cb3fc5ddb66789fd9F691F0D3359F57fcCf",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0x23AFa075aE5BC3EbBeae1308553a69e8C0b35B1c",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0x631B471033BeE99b8c9E9854D804a4b22A8e351e",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x83EE8bbA342395e37447f3252E7520d505157525",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0xa3fA177610824036fc7bB5A0E328e363988d99b6",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x4BC973F69024b8B8f4b2d9936B3cFf70095cB327",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
