import { Tranche, PoolType, Tokens } from "../constant";

export const strategy12 = "0xF44d84c7430152Ddf1CF6ccD8A46544A08099bd1";

export const epoch12Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0x18cd69117a417f50757C60c9f9Bf3e46D46C9B77",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0x2a273CF0C26C0Eaad75dDee4b4e33185dE5B24eD",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0xb23011aeFa2073f8066D4BfabB18a2115e65Fb54",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0xd28009EDcFaC82123755e024e1f4A0510a1e0947",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x085F461155D30EE9634eAc4bd6058eCf93b19570",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 15.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x001E13f961752B76e69196C80df73749e2A266E2",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0x5AAb0684B93eB6f34cbCC959548Eec27E0344D33",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0xe2F005edA918cF3c830e2A27B09143C506B3aF3e",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0xC7CE93220141134e8fa879eDEDec8dF7d6Cb402B",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0x8B621964D5EEB47226864259a1ef014c9dd9267A",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x24392a72710Ba728d46F89cA0e5f4622A07CAbBB",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x2c8373a15681511fE3060d3c989551909Ba2dE0C",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x2facB970Fcc021BD6eAf5E909488B833A84a96B3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
