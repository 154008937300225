import styled from "styled-components";

const TextButton = styled.button`
  color: ${({ theme }) => theme.link.color};
  border: none;
  cursor: pointer;
  background: none;
  &:hover {
    color: ${({ theme }) => theme.link.hover};
  }
`;

export default TextButton;
