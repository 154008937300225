import { Tranche, PoolType, Tokens } from "../constant";

export const strategy18 = "0x75a154c5177a631f32771B4cAb9466bd777C3291";

export const epoch18Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0xE7cdb2b13789EdC7501d49825F2052a79A2967E7",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0x919191982BB75FC6F1a679068ca4Cd675316F867",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x81341413Bec83C61676A163B4Bc2F27475dD2D0A",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0xb352581b47e531cC7ce9d8bf2460Ce712B2e8E0A",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0xb5C87b2544A57284fa1104d0dC08C7FE17Eb33Ca",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 15.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x71b12da8114f9A5f86f4BB9D37962dEE622E491B",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0xE4e19ae5C7bD59074eaC2ad17542E77b0edf3E1d",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0x6b00dA74Da51B1eCcBd05D979C1dF075FD46ff45",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0xbfa3Ab1cD963ea51E01E8b2Fe1AbC40b3C991d71",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0xa4e5251EC719c733381491E54FaF1cE1d98DA133",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0xc34B39eB9F9E3E547c53174E8E1b02B0104E3004",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x3E707758E439281572F7294eeDEE0B40053c8f94",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x9C8Eb405e87338b92A5d5c4eaa64222fb94b12f4",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
