import { Tranche, PoolType, Tokens } from "../constant";

export const strategy8 = "0x854E3dd6231CDa9589Be8964B03f7B1baF4eb6EA";

export const epoch8Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0xd9BCac080bbCf7F30600E5D2953af423FE6535F0",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0x36D7EC9c62FB1B8278D80A76cA2874727771B266",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0x750035E08048bCaD68498c808F0bbBCe50dA1fD2",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0x2d3043374084BE56aAeA9459e3688c13BEC0DbB0",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x5d9add5e8071b6b0f44C8F5451BBCc7E7a2586B3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x45629b830ad12E8272b28B0bd201782403d09C39",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0x8d6731A6321F6ddf4973BA58ee3D2e960B67db22",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0x6f664e33d23582a550BD4d471A76EaAb251d06Ea",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0xf4662e90860aB806AD4D75A1F735001EAB3dc0A2",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0xFaA5790E21cB443305a25703C3F8D73B8205C7cf",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x4Ac1ae8271a2F87b7463b8Fd9B87500182F31715",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x8E466A663D6d50027ed6f33Df0ed8d480B201022",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x140b4f2ff45DBbB060D438736d8e43904e3f60F4",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
