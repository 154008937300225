import { Tranche, PoolType, Tokens } from "../constant";

export const strategy14 = "0xC2065375E90fB7F4C6809bBEC9B4cE2658bE0B43";

export const epoch14Pools = [
  {
    index: 0,
    name: "DAI/Compound",
    address: "0x53E398043E4ABc14f58Df84B17e5440E7FE8f1d3",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 1,
    name: "Uni SFI/ETH",
    address: "0xeE176E63bD034ebd2F1C8d8A29b6767037935C43",
    type: PoolType.UniPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 2,
    name: "SFI Staking",
    address: "0xCf0dDfB39A524441e0fB55960796d65Bd6e61cFC",
    type: PoolType.StakingPool,
    pair: [Tokens.SfiStaking],
    reward: 22.5,
  },
  {
    index: 3,
    name: "UNI SFI/BTSE",
    address: "0x2114870CEcAC45435ABD9D5555B088FA48A2f369",
    type: PoolType.UniPool,
    pair: [Tokens.Btse, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 4,
    name: "WBTC/Compound",
    address: "0x94D30245191E48F5e7CfE0B9A6f357948F3f9de9",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Wbtc, Tokens.Comp],
    reward: 15.5,
  },
  {
    index: 5,
    name: "UNI SFI/GEEQ",
    address: "0x1Eb633d90a41679947494E1Dc29c08DcC34D77d7",
    type: PoolType.UniPool,
    pair: [Tokens.Geeq, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 6,
    name: "UNI SFI/ESD",
    address: "0xc0c14A7D5388154282433835FE055935E00aCd4c",
    type: PoolType.UniPool,
    pair: [Tokens.Esd, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 7,
    name: "Sushi SFI/ETH",
    address: "0x7b847990993747f64eB4279978cC260A6CA6E259",
    type: PoolType.SushiPool,
    pair: [Tokens.Sfi, Tokens.Eth],
    reward: 33.75,
  },
  {
    index: 8,
    name: "UNI ibETH/ALPHA",
    address: "0x49f71e3Fab2767b3f148D8DF534fa948AF226ce4",
    type: PoolType.UniPool,
    pair: [Tokens.ibeth, Tokens.Alpha],
    reward: 1.5,
  },
  {
    index: 9,
    name: "DAI/RARI",
    address: "0xD98717C1f19d83A3d1eC194Bf86ea8799d15dA63",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Dai, Tokens.Rari],
    reward: 10.5,
  },
  {
    index: 10,
    name: "UNI SFI/PRT",
    address: "0x35645E4972257b4d0bED7F32F9e657409fa3f7A1",
    type: PoolType.UniPool,
    pair: [Tokens.Prt, Tokens.Sfi],
    reward: 1.5,
  },
  {
    index: 11,
    name: "USDT/Compound",
    address: "0x19CD935Af1E19C44E310E85949C7E73fbC933D7c",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdt, Tokens.Comp],
    reward: 10.5,
  },
  {
    index: 12,
    name: "USDC/Compound",
    address: "0x4Cf7aAd1eF066Ded903e3D3A0c81387678465595",
    type: PoolType.Tranche,
    detail: [Tranche.S, Tranche.A],
    pair: [Tokens.Usdc, Tokens.Comp],
    reward: 10.5,
  },
];
